import content from 'content/content.json';
import Hero from 'components/shared/Hero';
import Layout from 'components/Layout';
import React from 'react';

export default class About extends React.Component {
	render() {
		return (
			<Layout title="About">
				<Hero
					title={content.pages.about.hero.title}
					content={content.pages.about.hero.content}
				/>
			</Layout>
		);
	}
}
